<template>
  <div>
    <a-card title="薪资结算单创建">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <a-spin :spinning="loading">
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-row>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="number" label="结算编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="employee" label="员工">
                <a-select
                  placeholder="员工选择"
                  v-model="form.employee"
                  allowClear
                  @change="handleEmployeeChange"
                  style="width: 200px"
                >
                  <a-select-option
                    v-for="option in employeeItems"
                    :key="option.id"
                    :value="option.id"
                  >
                    <span>{{ option.name }}</span>
                    <!-- <span>{{ option.name }}</span> -->
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="payment_date" label="月份">
                <a-month-picker
                  placeholder="选择月份"
                  :value="form.payment_date"
                  :disabled-date="disabledDate"
                  @change="handleMonthChange"
                >
                </a-month-picker>
              </a-form-model-item>
            </a-col>

            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider orientation="left">未结算考勤</a-divider>
        <div>
          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              size="middle"
              :columns="columns"
              :loading="tb_loading"
              :data-source="productsData"
              :pagination="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :scroll="{ y: '400px' }"
            >
            </a-table>
          </div>
        </div>
        <a-divider orientation="left">工资信息</a-divider>
        <div>
          <a-row gutter="16">
            <a-col :span="4">
              <a-form-model-item
                prop="base_salary"
                label="基本月工资"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="employee.salary_month"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item
                prop="overtime_pay"
                label="加班"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  :value="attendanceSum"
                  style="width: 100%"
                  disabled
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item
                prop="absence_deductions"
                label="缺勤"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  :value="absenceSum"
                  style="width: 100%"
                  disabled
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item
                prop="social_security"
                label="社保"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="form.social_security"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item
                prop="provident_fund"
                label="公积金"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="form.provident_fund"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item
                prop="personal_tax"
                label="个税"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="form.personal_tax"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item
                prop="other_amount"
                label="其他费用"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="form.other_amount"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item
                label="总计金额(元)"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  :value="TotalAmount"
                  :disabled="true"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item
                label="实际支付金额(元)"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="collection_amount"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>

        <!-- <a-divider orientation="left">公司承担社保公积金</a-divider>
        <div>
          <a-row gutter="16">
            <a-col :span="4">
              <a-form-model-item
                prop="social_security_com"
                label="社保"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="form.social_security_com"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item
                prop="provident_fund_com"
                label="公积金"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="form.provident_fund_com"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

          </a-row>
          

        </div> -->

        <a-divider orientation="left">上传付款截图</a-divider>
        <div>
          <a-col :span="24" :md="24">
            <a-upload
              action="/api/salary_record_images/"
              list-type="picture-card"
              :headers="{
                'X-CSRFToken': Cookies.get('csrftoken'),
                Authorization: `Bearer ${Cookies.get('access')}`,
              }"
              :file-list="form.image_items"
              @preview="handlePreview"
              @change="handleChange"
              :before-upload="beforeUpload"
              name="image"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-col>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading" :disabled="button_dis"
            >批量付款</a-button
          >
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import moment from "moment";
import { salaryRecordsCreate, getSalaryRecordsNumber } from "@/api/hrms";
import { unpaidWorkList } from "@/api/hrms";
import { employeeOption } from "@/api/option";
import NP from "number-precision";

export default {
  data() {
    return {
      Cookies,
      selectedRowKeys: [],
      collection_amount: "",
      button_dis: false,
      tb_loading: false,
      loading: false,
      model: {},
      form: {
        employee: null, // 初始化为空，或具体的员工对象
      },
      employee: {}, // 存选中的员工完整对象

      // 图片相关
      fileList: [],
      previewVisible: false,
      previewImage: "",

      //正式工相关
      employeeItems: [],
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: `Bearer ${Cookies.get("access")}`,
      },

      rules: {
        number: [
          { required: true, message: "请输入编号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        employee: [
          { required: true, message: "请选择员工", trigger: "change" },
        ],
        payment_date: [
          { required: true, message: "请选择月份", trigger: "change" },
        ],
        other_amount: [
          {
            pattern: new RegExp(/^\d{0,14}(?:\.\d{0,2})?$/),
            message: "其他费用格式不正确",
            trigger: "change",
          },
        ],
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "姓名",
          dataIndex: "employee_name",
          key: "employee_name",
        },
        {
          title: "工作日期",
          dataIndex: "work_date",
          key: "work_date",
        },

        {
          title: "日薪",
          dataIndex: "salary_day",
          key: "salary_day",
          customRender: (value, item) => {
            if (item.isTotal) return item.totalAmount;
            return item.salary_day;
          },
        },

        {
          title: "类型",
          dataIndex: "work_type",
          key: "work_type",
          customRender: (value, item) => {
            if (!item.isTotal) {
              if (item.employee_type == "permanent") {
                return item.work_type == "Attendance" ? "加班" : "缺勤";
              } else {
                return "出勤";
              }
            }
          },
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          // width:'110px'
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "用户备注",
          dataIndex: "remark",
          key: "remark",
        },
      ],
      productOrderItems: [],
    };
  },
  computed: {

    TotalAmount() {
      // base_salary
      let totalAmount = parseFloat(this.employee.salary_month || 0);

      // 加上加班
      totalAmount = NP.plus(totalAmount, this.attendanceSum || 0);

      // 减去缺勤
      totalAmount = NP.minus(totalAmount, this.absenceSum || 0);

      // 再减去社保、公积金、个税、其他费用
      totalAmount = NP.plus(
        totalAmount,
        this.form.provident_fund || 0,
        this.form.social_security || 0,
        this.form.personal_tax || 0,
        this.form.other_amount || 0
      );
      return totalAmount;
    },
    productOrderItemsSelect() {
      return this.productOrderItems.filter((item) =>
        this.selectedRowKeys.includes(item.id)
      );
    },

    productsData() {
      // 统计合计
      // return []
      let totalAmount = 0;

      for (let item of this.productOrderItemsSelect) {
        totalAmount = NP.plus(totalAmount, item.salary_day);
      }
      return [
        ...this.productOrderItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          totalAmount,
        },
      ];
    },
    //缺勤
    absenceSum() {
      return this.productOrderItemsSelect
        .filter((item) => item.work_type === "Absence")
        .reduce((acc, item) => acc + parseFloat(item.salary_day || 0), 0);
    },
    // 计算 Attendance 总和
    attendanceSum() {
      return this.productOrderItemsSelect
        .filter((item) => item.work_type === "Attendance")
        .reduce((acc, item) => acc + parseFloat(item.salary_day || 0), 0);
    },
  },
  methods: {
    moment,
    initData() {
      this.resetForm();
      var employee_type = this.$route.query.employee_type;
      if (employee_type) {
        this.t_employee = true;
      }
      employeeOption({
        page_size: 999999,
        is_active: true,
        employee_type: "permanent",
      }).then((data) => {
        this.employeeItems = data.results;
      });
    },

    getMonthStartAndEnd() {
      var date = this.form.payment_date_;
      const start = new Date(date.getFullYear(), date.getMonth(), 1);
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return {
        start_date: start.toISOString().slice(0, 10), // 格式化为 YYYY-MM-DD
        end_date: end.toISOString().slice(0, 10), // 格式化为 YYYY-MM-DD
      };
    },

    handleMonthChange(data, dateString) {
      const date = moment(dateString, "YYYY-MM").toDate();
      this.form.payment_date_ = date;
      this.form.payment_date = dateString;
      this.get_work_list();
    },
    handleEmployeeChange(id) {
      // 根据选中的id从列表中查找完整对象
      this.employee = this.employeeItems.find((item) => item.id === id) || {};
      if(this.employee.name=='郭震'){
        this.form.provident_fund =400
      }else{
        this.form.provident_fund =0
      }
      this.get_work_list();
    },
    get_work_list() {
      if (this.form.employee && this.form.payment_date) {
        const { start_date, end_date } = this.getMonthStartAndEnd();
        console.log("Fetching data for:", start_date, "to", end_date);
        console.log(start_date, end_date);
        this.tb_loading = true;
        unpaidWorkList({
          page_size: 999999,
          employee: this.form.employee,
          start_date: start_date,
          end_date: end_date,
        })
          .then((data) => {
            this.productOrderItems = data;
            this.selectAllRows();
          })
          .finally(() => {
            this.tb_loading = false;
          });
      } else {
        console.log("no data");
      }
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    selectAllRows() {
      // 假设每个数据项有一个唯一标识符 'id'
      this.selectedRowKeys = this.productOrderItems.map((item) => item.id);
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    create() {
      const validOrderId = this.selectedRowKeys.filter((key) => key !== "-1");
      if (validOrderId.length === 0 & this.productOrderItems.length > 0) {
        this.$message.warning("未选择考勤结算");
      }
      let payment_images = this.form.image_items.map((item) => {
        return item.response.id;
      });

      // if (payment_images.length == 0) {
      //   this.$message.error("请提供付款凭证");
      //   return;
      // }
      if (this.collection_amount <= 0) {
        this.$message.error("付款金额为空");
        return;
      }

      this.loading = true;
      let { number, other_amount, remark, } = this.form;
      let formData = {
        number,
        other_amount,
        remark,
        employee: this.employee.id,
        total_paid_amount: this.collection_amount,
        total_arrears_amount: this.TotalAmount,
        payment_images,
        payment_orders: validOrderId,
        base_salary: this.employee.salary_month,
        overtime_pay: this.attendanceSum,
        absence_deductions:this.absenceSum,
        social_security:this.form.social_security,
        provident_fund:this.form.provident_fund,
        personal_tax:this.form.personal_tax,
        payment_date:this.form.payment_date,

        social_security_com:this.form.social_security_com,
        provident_fund_com:this.form.provident_fund_com
      };
      console.log("formData", formData);
      salaryRecordsCreate(formData)
        .then((data) => {
          this.$message.success("创建成功");
          // this.$router.push({
          //   path: "/hrms/payment_salary_view",
          //   query: { employeeId: this.employee.id },
          // });
        }) .catch((err) => {
              // 判断错误是否有响应对象
              if (err.response) {
                // 处理包含错误的响应数据
                const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
                this.$message.error(errorMessage);
              } else {
                // 处理其他类型的错误（例如网络错误）
                this.$message.error("请求错误");
              }
            })
        .finally(() => {
          this.loading = false;
        });
    },

    async handlePreview(file) {
      console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await this.$functions.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    handleChange({ fileList }) {
      console.log("fileList", fileList);
      // 过滤出上传完成的文件
      const completedFiles = fileList.filter((file) => file.status === "done");

      // 如果需要，处理这些文件的response数据
      completedFiles.forEach((file) => {
        if (file.response) {
          console.log("Upload success response:", file.response);
        }
      });
      this.$set(this.form, "image_items", fileList);
    },

    handleCancel() {
      this.previewVisible = false;
    },

    resetForm() {
      this.form = { image_items: [], other_amount: 0, social_security: 1150.47,provident_fund_com:0 };
      // this.form = {other_amount: 0 };
      getSalaryRecordsNumber().then((data) => {
        this.form = { ...this.form, number: data.number };
      });
    },
  },
  mounted() {
    console.log("hallo");
    this.initData();
  },
};
</script>

<style scoped>
.highlight-cell {
  background-color: yellow; /* 或者使用更具体的颜色代码，如 #ffff00 */
}
</style>